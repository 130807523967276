import React from "react";
import { Link } from "gatsby";
import { FaInstagram } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import Logo from "../Logo";

function Header() {
  return (
    <header>
      <div className="announcement__bar">
        <div className="container">
          <p>2023 Finalist Local Business Awards</p>
        </div>
      </div>
      <div className="header__content">
        <h4 className="hide-mobile">ROSEBERRY, SYDNEY</h4>
        <Link to="/">
          <Logo />
        </Link>
        <div className="header__links hide-mobile">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/lialashbeauty/"
            >
              <FaInstagram /> LIALASHBEAUTY
            </a>
          </li>
          <li>
            <a href="tel:0422470540">
              <FaPhone /> 0422 470 540
            </a>
          </li>
        </div>
      </div>
      <div className="sticky__content">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="header__subnav">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://bookings.gettimely.com/lialashbeauty/bb/book"
        >
          Book Now
        </a>
        <a href="/academy">Academy</a>
        <a href="/policy">Policies</a>
        <a href="#contact">Contact</a>
      </div>
      <div className="sticky__footer">
        <div className="header__links">
          <li>
            <a href="tel:0422470540">
              <FaPhone /> 0422 470 540
            </a>
          </li>
        </div>
        <div className="header__links">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/lialashbeauty/"
            >
              <FaInstagram /> @LIALASHBEAUTY
            </a>
          </li>
        </div>
      </div>
    </header>
  );
}

export default Header;
