import React from "react";
import logoImage from "../../images/logo.png";

const Logo = () => {
  return (
    <div className="logo">
      <img src={logoImage} alt="LiaLash Beauty Roseberry" />
    </div>
  );
};

export default Logo;
