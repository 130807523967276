import React from "react"
import Header from "../Header"
import SEO from "../seo"
import Footer from "../Footer"
import "normalize.css"
import "../../styles/main.scss"

function Layout({ children }) {
  return(
      <>
        <SEO />
        <Header/>
        <main>
            <div className="full-container">
                {children}
            </div>
        </main>
        <Footer/>
      </>
  )
}

export default Layout;
