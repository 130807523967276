import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "@react-icons/all-files/fa/FaStar";


function Testimonials() {
  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
      <div className="testimonial__slide">
        <p className="stars"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p>
        <p>I have been searching for a lash technician that uses premium lashes and I am so happy to have found Lialash Beauty. I love Allison's work because I notice the instant lightness & comfortability over my natural lashes. I do it for a everyday look so quality is very important to me. She has attention to detail and really puts in the effort for each fan and eye lash application. I will be a client forever. Highly recommended &amp; worth every dollar.</p>
        <p>- Jessica Nguyen</p>
      </div>
      <div className="testimonial__slide">
      <p className="stars"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p>
        <p>Allison is the most amazing lash technician! I was recommended by my sister and have never looked back. My eyelashes always have the best retention and she is also currently training me to become a lash technician myself. There is no one else I trust other than Allison to do my lashes and to also train me. Highly recommend her services</p>
        <p>- Tiffany Eang</p>
      </div>
      <div className="testimonial__slide">
      <p className="stars"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p>
        <p>Allison is an amazing eyelash artist. She makes my eyelashes look so naturally beautiful! Allison is the
            only one I trust with my lashes. I'm always getting compliments on how great they look. You will not regret
            your choice with her! She is AMAZING!
        </p>
        <p>- Drina K</p>
      </div>
      <div className="testimonial__slide">
      <p className="stars"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p>
        <p>Lialash Beauty is by far the BEST lash technician I've been too. Allison is always professional, clean and
            tidy and a joy to see. Her bubbly and warm personality makes you feel welcomed, relaxed and
            comfortable throughout your appointment! Could not recommend her more. My lashes are flawless every
            time !!</p>
            <p>- Emily Youkhana</p>
      </div>
      <div className="testimonial__slide">
      <p className="stars"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p>
        <p>Wonderful to deal with. Very professional and she always looks after my lashes and ensures the utmost
            diligence is put into application and post-care. (She also doesn't cut your lashes which in my experience
            some salons have done, so that is a big plus for me :)</p>
        <p>- Karina Lozada</p>
      </div>
    </Slider>
  );
}

export default Testimonials;