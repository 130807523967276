import React from "react";
import { Link } from "gatsby";
import Logo from "../Logo";
import Testimonials from "../Testimonials";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <div className="section section__testimonials">
          <h2>Testimonials</h2>
          <p className="review__link">
            See all reviews on google{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/search?q=lialash+beauty&oq=lialash+beauty&aqs=chrome.0.0i512j46i175i199i512j0i10i30j69i60j69i61j69i60.2105j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6b12b90dd7d27797:0x312ee35d23a6859a,1,,,"
            >
              here
            </a>
          </p>
          <Testimonials />
        </div>
        <div className="footer__container">
          <h2 id="contact">Get in Touch</h2>
          <div className="contact">
            <div className="left__content">
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                className="contact__form"
              >
                <p>
                  <label>Name: </label>
                  <input type="text" name="name" />
                </p>
                <p>
                  <label>Email: </label>
                  <input type="email" name="email" />
                </p>
                <p>
                  <label>Number: </label>
                  <input
                    type="tel"
                    name="phone"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  />
                </p>
                <p>
                  <label>Message: </label>
                  <textarea name="message"></textarea>
                </p>
                <a className="button" type="submit">
                  Send
                </a>
              </form>
            </div>
            <div className="right__content">
              <p>
                <strong>Location:</strong>Private Studio located in Rosebery
                2018
              </p>
              <p>
                <strong>Tel:</strong> 0422 470 540
              </p>
              <p>
                <strong>Email: </strong>
                <a href="mailto:Lialashbeautyco@gmail.com">
                  Lialashbeautyco@gmail.com
                </a>
              </p>
              <a className="button" href="/bookappointment">
                Book Now
              </a>
            </div>
          </div>
          <Link to="/">
            <Logo />
          </Link>
          <p>© {currentYear} — LiaLash Beauty</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
